// @ts-nocheck
/* eslint-disable */

import { Tracker } from '@app-services/tracker/tracker.service'

const container = document.querySelector<HTMLElement>('[data-spotler-activate]')

if (! container) {
    console.warn('No Spotler Activate HTML container found')
} else {
    const reference = container?.dataset.spotlerActivate!

    loadSpotlerScript(reference)

    Tracker.pushSpotlerData({ event: 'PageView' })
}

function loadSpotlerScript(accountId: string): void {
    (function(s,q,z,l,y){s._sqzl=s._sqzl||[];l=q.createElement('script'),
        y=q.getElementsByTagName('script')[0];l.async=1;l.type='text/javascript';
        l.defer=true;l.src=z;y.parentNode.insertBefore(l,y)})
    (window,document,`https://squeezely.tech/tracker/${accountId}/sqzl.js`);
}
